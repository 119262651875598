import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../../hooks/useForm";
import { useError } from "../../../../../hooks/useError";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  AttendeeExpress,
  ExpressMeeting,
} from "../../../../../types/dashboard";
import {
  createExpressMeeting,
  createExpressMeetingQueue,
  getPhonePrefixes,
  isZipCode,
} from "../../../../../assets/api";
import {
  Button as ButtonSemantic,
  Dimmer,
  Dropdown,
  Form,
  Input,
  Loader,
  Modal,
  Segment,
  Select,
  TextArea,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { EventMeeting } from "../../../../../models/EventMeeting";
import Field from "../../../../../components/Field/Field";
import moment from "moment";
import { getSlotsByDay, getSlotsFromService, putSlotsReserved } from "../../../../../assets/slots"
import { Slot } from "../../../../../models/Slot";
import { async } from "rxjs";
import { getAllSelectedOptions } from "office-ui-fabric-react";
import { SyntheticEvent } from "react-draft-wysiwyg";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import i18n from "../../../../../configs/i18n";

interface Props {
  modal: boolean;
  type: string;
  service: any;
  count: number;
  queueCount: (T: number) => any;
  area: string;
  handleOpenModal: (T: boolean) => void;
  getNextEvent: (meeting: string) => any;
}

export const ModalExpressMeeting: React.FC<Props> = ({
  modal,
  type,
  count,
  queueCount,
  service,
  area,
  handleOpenModal,
  getNextEvent,
}) => {
  const authContext = useContext(AuthContext);
  const codeUser = authContext.account.profile.code;
  const defaultAttendee: AttendeeExpress = {
    culture: "",
    email: "",
    name: "",
    surname: "",
    isOrganizer: false,
    secondSurname: "",
    id: "",
    phone: "",
    type: "Citizen",
    comment: "",
    isSendOTP: true,
  };
  const [hours, setHours] = useState<any[]>([]);
  const [slotId, setSlotId] = useState<any>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [create, setCreate, handleInputChange, reset] =
    useForm(defaultAttendee);
    const [prefix, setPrefix] = useState<any>("+34");

  const [createError, setCreateError] = useState({
    culture: false,
    email: false,
    name: false,
    surname: false,
    id: false,
    postalCode: false,
    phone: false,
    comment: false,
  });
  const handleCloseExpress = async () => {
    await handleOpenModal(false);
  };

  const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  const handleSubmitExpress = async () => {
    await setLoading(true);
    let mins, hour, sec, utc, duration;

    if(type === 'PROGRAMMED'){
      const date = slotId.slice(0, 8);
      const startHour = slotId.slice(9, 11) + ':' + slotId.slice(11, 13);
      const endHour = slotId.slice(14, 16) + ':' + slotId.slice(16, 18)
      
      utc = date.slice(0,4) + '-' + date.slice(4,6) + '-' + date.slice(6,8);
      const today = moment(utc).add(startHour, 'hours');
      const todayEnd = moment(utc).add(endHour, 'hours');
      
      mins = today.format("mm");
      hour = today.format("HH");
      sec = today.format("ss");

      duration = moment
        .duration(moment(todayEnd, 'YYYY/MM/DD HH:mm')
        .diff(moment(today, 'YYYY/MM/DD HH:mm'))
        ).asMinutes();
    }else{
      utc = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      let today = moment().add(5, "minutes");
  
      mins = today.format("mm");
      hour = today.format("HH");
      sec = today.format("ss");

      duration = 30;
    }

    const attendee = create;
    attendee.culture = "ca";
    attendee.id = create.id;
    attendee.secondSurname = "";

    if (
      attendee.id === "" ||
      attendee.id === null ||
      attendee.id === undefined
    ) {
      attendee.id = "11111111A";
    }
    
    attendee.phone = `${prefix}${attendee.phone}`;
    console.log("Test Servicio: ",service)
    const express: ExpressMeeting = {
      durations: duration,
      start: `${utc}T${hour}:${mins}:${sec}`,
      serviceCode: service,
      attendees: [attendee],
      zipCode: create.postalCode,
      observations: create.observations,
      comment: create.comment,
      isOtpAvailable: create.isSendOTP,
      isProgrammed: (type === 'PROGRAMMED' ? true : false)
    };
    const { backoffice } = await authContext.getTokenForScopes();

    try {
      if(attendee.postalCode!==""){
        const responseEventPostalCode: Response = await isZipCode(attendee.postalCode,backoffice)
      if (!responseEventPostalCode.ok){
        toast(t("postal-code.error"), { type: "error"})
        express.zipCode=""

      }
      else if(responseEventPostalCode.status===204){
        toast(t("postal-code.info"), { type: "info"})
        express.zipCode=""

      }}
      const responseEventMeeting: Response = await createExpressMeeting(
        express,
        backoffice
      );

      if (!responseEventMeeting.ok)
        throw new Error(t("home.createModal.error.create"));

      if(responseEventMeeting.ok && type === 'PROGRAMMED'){
        // const responseSlotIdDetails = await getSlotsFromService(
        //   service,
        //   slotId,
        //   backoffice
        // );

        // if(responseSlotIdDetails.ok){
        //   const slotResponse: Slot = await responseSlotIdDetails.json();
         // slotResponse.roomReservedCount += 1;
      

        //   const responseSlotIdReserve = await putSlotsReserved(service, slotResponse, backoffice);
        //   if(responseSlotIdReserve.ok)
               toast(t("home.createModal.program_ok"), { type: "success"});
        // }
      }

      if(type === 'EXPRESS'){
        const dataEventMeeting: EventMeeting = await responseEventMeeting.json();
  
        if (dataEventMeeting.code === "")
          throw new Error(t("home.createModal.error.create"));
  
        const responseQueue = await createExpressMeetingQueue(
          area,
          dataEventMeeting.code,
          codeUser,
          backoffice
        );
  
        if (!responseQueue.ok)
          throw new Error(t("home.createModal.error.create"));
  
        await queueCount(count + 1);
  
        await getNextEvent(dataEventMeeting.code);
      }
    } catch (error) {
      toast((error.message), { type: "error"});
    }

    reset();
    handleOpenModal(false);
    setLoading(false);
  };

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  function validatePhone(phone: string) {
    const re = /^\d{9,15}$/;
    return re.test(String(phone).toLowerCase());
  }
  const isValid = (form: any) => {
    const isEmailValid = validateEmail(form.email);
    const isPhoneValid = validatePhone(form.phone);
    return (
      isEmailValid &&
      isPhoneValid &&
      form.name !== "" &&
      form.surname !== "" &&
      form.id !== ""
    );
  };

  const getFormat = (key: string) => {
    if (key === "phone") return "phone";
    else if (key === "email") return "email";
    else if (key === "id") return "text";
    else if (key === "comment") return "text";
    else if (key === "isSendOTP") return "boolean";
    else if (key === "postalCode")return "text";
    else return "text";
  };



  const getSlotFromDay = async (date: Date) => {
    setHours([]);
  
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    const { backoffice } = await authContext.getTokenForScopes();
  
    let day1 = day.toString();
    let month1 = month.toString();
    if (month < 10) month1 = "0" + month1;
  
    if (day < 10) day1 = "0" + day1;
  
    const result: Slot[] = await getSlotsByDay(day1, month1, year.toString(), service, backoffice);
  
    setHours(
      result
        .filter((slot: Slot) => {
          const date = slot.start.slice(0, 4) + "-" + slot.start.slice(4, 6) + "-" + slot.start.slice(6, 8);
          const start = slot.start.slice(8, 12);
          const hours = start.slice(0, 2) + ":" + start.slice(2, 4);
  
          const current = moment(date).add(hours, "hours");
          const today = moment();
  
          if (today.isBefore(current)) return slot;
        })
        .map((slot: Slot) => {
          const start = slot.start.slice(8, 12);
          return {
            key: slot.slotId,
            value: slot.slotId,
            text: start.slice(0, 2) + ":" + start.slice(2, 4),
          };
        })
    );
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const CustomInput = ({ value, onClick }: any) => (
    <div className="date-picker-input" onClick={onClick}>
        <span className="date-picker-value">{value}</span>
        <FaCalendarAlt className="date-picker-icon" />
        <style>
            {`
          .date-picker-input {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 9px;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: #fff;
            width: 100%;
            height: 100%;
            min-height: 41px;
          }
  
          .date-picker-value {
            margin-right: 5px;
          }
  
          .date-picker-icon {
            margin-left: auto;
          }
          .react-datepicker {
            font-size: 1em;
          }
  
          .react-datepicker__month {
            margin: 0.4em 1em;
          }
          .react-datepicker__day-name, .react-datepicker__day {
            width: 1.9em;
            line-height: 1.9em;
            margin: 0.166em;
          }
          .react-datepicker__current-month {
            font-size: 1em;
          }
        `}
        </style>
    </div>
);

  return (
    <Modal onClose={handleCloseExpress} open={modal} closeIcon>
      {loading ? (
        <Fragment>
          <Modal.Content>
            <Segment padded={"very"} size={"massive"}>
              <Dimmer active inverted>
                <Loader size="medium">{t("loading.cita_express")}</Loader>
              </Dimmer>
            </Segment>
          </Modal.Content>
        </Fragment>
      ) : (
        <Fragment>
          <Modal.Header>
            <h2>{t("home.createModal.header")}</h2>
          </Modal.Header>
          <Modal.Content>
            <Form className="border-0" style={{ boxShadow: "none" }}>
              <h3 className="mb-5">{t("home.createModal.subheader")}</h3>
              {type === "PROGRAMMED" && (
                <>
                  <Field 
                    name={"slots"}
                    title={t(`home.createModal.day`)}
                    required
                  >
                    <DatePicker 
                      selected={selectedDate} 
                      className="datepicker_style"
                      onChange={(date) => {
                        setSelectedDate(date); 
                        getSlotFromDay(date);
                      }}  
                      dateFormat="yyyy/MM/dd" 
                      placeholderText={t("home.createModal.select_date")} 
                      customInput={<CustomInput />}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale={i18n.language}
                    />
                  </Field>

                  <Field 
                    name={"slots"}
                    title={t(`home.createModal.hours`)}
                    required
                  >
                    <Select loading={hours.length === 0} options={hours} onChange={(event, data) => setSlotId(data.value)} fluid />
                  </Field>
                </>
              )}
              {Object.entries({
                phone: create.phone,
                email: create.email,
                name: create.name,
                surname: create.surname,
                id: create.id,
                postalCode: create.postalCode,
                comment: create.comment
              }).map(([key, value]) => (
                <>
                  {key === "comment" ?
                  <>
                    <Field
                      title={t(`home.createModal.${key}`)}
                      textError={t(`home.createModal.${key}_error`)}
                      format={'getFormat(key)'}
                    >
                      <TextArea name={key} value={value} onChange={handleInputChange}/>
                    </Field>
                   </> 
                  :
                    <>
                      <Field
                        format={getFormat(key)}
                        required={key !== "postalCode"? true:false}
                        label={key === "phone" && <Dropdown defaultValue='+34' options={getPhonePrefixes() } onChange={(event: SyntheticEvent, data: object) => setPrefix(data)} selection search/>}
                        title={t(`home.createModal.${key}`)}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                        textError={t(`home.createModal.${key}_error`)}
                      />
                    </>
              }
                </>
              ))}
              { type === 'PROGRAMMED' && 
                <Field
                  title={t(`home.createModal.observations`)}
                  textError={t(`home.createModal.observations_error`)}
                  format={'text'}
                >
                  <TextArea name={'observations'} value={create.observations} onChange={handleInputChange}/>
                </Field>
              }
              { type === 'EXPRESS' && 
                <Field
                format={'boolean'}
                required={true}
                title={t(`home.createModal.isSendOTP`)}
                name={'isSendOTP'}
                value={create.isSendOTP}
                onChange={handleInputChange}
                textError={t(`home.createModal.isSendOTP_error`)}
              />
              }
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <ButtonSemantic
              negative
              content={t("home.createModal.cancel")}
              onClick={handleCloseExpress}
            />
            <ButtonSemantic
              disabled={!isValid(create)}
              positive
              icon="checkmark"
              content={t("home.createModal.end")}
              onClick={handleSubmitExpress}
            />
          </Modal.Actions>
        </Fragment>
      )}
    </Modal>
  );
};

